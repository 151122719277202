<template>
  <div>
    <monitor-template ref="monitorRef" :mapPageParams="mapPageParams" @mapPageClick="handleMapPageClick" @mapToolClick="handleMapToolClick">
      <div slot="left">
        <!-- 左侧弹窗 -->
        <!-- 固定宽度体验更佳 -->
        <vxe-modal
          v-model="showLeftModal"
          title="路段"
          width="390px"
          height="90%"
          class="my-modal"
          @close="handleCloseLeftModal"
          show-zoom
          resize
          :lock-view="false"
          :mask="false"
          :position="{ top: '5%', left: '8%' }"
        >
          <template>
            <!-- 树形结构:保洁人员数据 -->
            <div class="vechicle-center">
              <object-tree
                style="height: 50%"
                ref="vehicleTree"
                :tree-data="treeData"
                place-holder="输入路段进行搜索"
                object-title="工牌"
                cache-key="CleanerZoneTree"
                object-icon="userIcon"
                :tree-selected-key="currentSelectedId"
                @select="clickCleanerNode"
                @check="checkCleanerNode"
              />
              <a-divider style="height: 1px; margin: 5px 0" />
              <!-- 操作按钮区 -->
              <a-row type="flex" justify="space-around"> </a-row>
              <a-divider style="height: 1px; margin: 5px 0" />
              <a-row>
                <a-col :span="24">
                  <a-tabs type="card" size="small" v-model="currentActiveTabKey" @change="handleTabChange">
                    <a-tab-pane key="1" tab="实时状态">
                      <MapCleanerInfoWindow :map-obj="MapObj" :objectInfo="objectInfo" :show-footer="false" />
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="历史轨迹">
                      <!-- 传入monitorRef是为了操作地图 -->
                      <map-history-track
                        :monitorRef="$refs.monitorRef"
                        :params="mapPageParams"
                        :historyTrackPointsArray="mapPageParams.historyTrackPointsArray"
                        @onHistoryTrackSearch="handleHistoryTrackSearch"
                      />
                    </a-tab-pane>
                  </a-tabs>
                </a-col>
              </a-row>
            </div>
          </template>
        </vxe-modal>
      </div>
    </monitor-template>
  </div>
</template>
<script>
// import { getDeviceTree } from '@/api/iot/raDevice'
// import { getCleanerData } from './cleanerData.js'
import MonitorTemplate from '../../../monitorTemplate'
import MapHistoryTrack from '../../../monitorComponents/mapHistoryTrack'
// 车辆树
import ObjectTree from '../../../monitorComponents/objectTree'
import MapCleanerInfoWindow from '../../../monitorComponents/mapInfoWindow/cleaner/department/object'
// import { listDeviceEmployee } from '@/api/jt808/deviceEmployee'
// import { employeeAccountTree } from '@/api/iot/employeeAccount'
import { Tabs, Collapse } from 'ant-design-vue'
import { queryHistoryCoordinates } from '@/api/jt808/deviceLocation'
import { treeArea } from '@/api/iot/device'
import { getAreaOnDutyByAreaIds } from '@/api/jt808/areaManager'
export default {
  name: 'MonitorCleaner',
  components: {
    MonitorTemplate,
    ObjectTree,
    MapHistoryTrack,
    MapCleanerInfoWindow,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel
  },
  data() {
    return {
      mapPageParams: {
        objectId: 'deviceId',
        timingUpdate: true,
        showHeader: true,
        initCountDown: 20,
        infoWindowTitle: 'bindName',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        moduleKey: 'cleaner',
        // 围栏模块key
        fenceModuleKey: 'cleanerZone',
        // 是否开放围栏
        isOpenFence: true,
        // 地图数据源
        mapDataSource: 'real',
        // 地图上左侧按钮显示文字
        leftModalTitle: '保洁监管-路段',
        // 地图上右侧按钮显示文字
        rightModalTitle: '',
        // 保洁人员显示图标
        objectIcon: { url: require('@/assets/images/demo/cleaner.png'), size: { width: 40, height: 43 } },
        // 保洁人员离线图标
        objectIconOff: { url: require('@/assets/images/demo/cleaner-off.png'), size: { width: 40, height: 43 } },
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        historyTrackPointsArray: [],
        lushuConfig: {
          playIcon:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAACoFBMVEUAAAAiq1XVhWHNbkcko1Qgu1oZtVMTnEYUnUcXi0IOgDhLEAhFCQAOvUwZv1cZu1Y2BwHDbE1aHBEXsFHqjn/3288YrlDwuqzipI757ObsrZQ+AgB9OiPpxbfbkW99Sz0Xp00XpEwAtUMWqEzcknF4RTcoplcYq09L2IwYrVAVlEQlp1ZnMiQYrlDcjGkYqU4VlUXNeFgYs1Lai2oYrE9iKhsZuFUAojwYtFNMEwzMaUsXiEFcJBghAAAZvFYWnUjQgGAYs1ISjEAYslEWm0fNeVpPFgwZtlQi44bJdVbfhmIZtVQSiz8Xpk0PhzzKdVZJDgAZvFYXpExskExFCgDUXkdHDABIDAD////knXsVnEgUk0QVmkcUlUUUkEMWoErknHoUoEgSij8PhTsMgTjoo4Dmn30goVAaoEwWo0sVnkgUmEYTj0IUjUH2r4vzrovimnoaqU8Yp00ilE0RnkYVl0YYk0YUlkQWkURwPzNJCgDq/PGo6cD/xJzvtppd0InxqobqpYLoooDxo36xinrbj3AtwmU4p2Eas1K5cFIhn1AdmUwalkkWnUgSmkQSjkARhz51RjgAnDcAoDJiLyNcJBVaHw5MFg1VGwz3//rv//nJ8tjB7tKX4bKK36p93aFnzZZs1ZTqrpLusJBj043qrIxX0IXson7cmn5PyX1Ey3c7xnTil3SqgnPfknJGuG82xGw/rGjYi2ifdWXNgGCXbF4gtlrJelokrljDeFiRZ1girlcbuVYon1SNYVIQuk8eok8Os0wPrksek0ocjUbdakYNnkPRXUEFpkAAsT+kXD8Cpj4Jkz0AqTsFlzsPgToLfTYAqTUApzUAlS8Aji+MSC8AmS6IPStmNiq/TyhwKBJMHRJMDwFBBQA4AAApAAD2NJViAAAAWHRSTlMAGf79++LSzsGhmJNTPjguLCIRD/38/Pv7+ff29fTz8u/q5uPe3tva2dTT0s3LyMjIuLiyr6+qpZ+dm5qXl5KQjo6Ff3p5dnFcW1pYVlVBOTknIR4XEAcEvw8j1QAAAfhJREFUSMft0lN75kAAhuHU2O12u7Zt27YxmehL8tlWbZtr27aN2v4rTdPjtHPePsfvfc0k12AD9euGbpg5ecGuE6jzU2uLHyQlPSsaPxgRrPR0el68/Zhf1n4aaT+stLjR29rkyS8qRTviSMff6FctXm9bSckgtC8uu2UyJj5Pf5NYvxHtShPilLjCZDRGp6OdsC9Zhosp8UVI4HBcQo8wm5ZjSG1RKEWgmBGBoTUpXiaCvRhiO+NxGS5LMEeggpPJ1/D7yhtLMdSO/zLHxFx9uA51HzZnRLPv7ac/Ju5Bmg/ZVHcZjPHz8x0N7q0O62vts2a2Je3/IwBGjhsLzqa5LdNXHOwVBEKHzvrhXSToLqo8VZWjI7f3dh0NxbBMasEFEcT+fvLZzlDZ4dJglcNAQY269o4IHlvVLKQMRKDkfvgol542EPaKl2eE/bkvKjvB0XrKelQKhGscetrNQ1gYJYBLhZks53bm6pjdUmA/Q9KcM8+lbbgugLsFUMdnGXJJ7TIpsDUbEvw3mmZ/vo8E5/9oWILLyqNJOFcKLISQktsyeaiquQiuVKu0nNNWSZBwihRYTArge4bcpf53E6R8VefIM2xVApgq+Zd2BC8JXe/P67WWlNhPrxlC7h8SOi9o87E+nseBkKBZAdMC5gdvO+SDDdRf6wLPsrNk4oM6zwAAAABJRU5ErkJggg==',
          // 不需要控制车辆方向
          enableRotation: false,
          // 轨迹播放时ICON上方显示的文字
          defaultContent: '',
          // 图标大小
          iconSize: { width: 48, height: 48 },
          // 图标的定位点相对于图标左上角的偏移值
          anchorSize: { width: 24, height: 24 }
        },
        // 是否显示地图轨迹明细
        showMapTrackDetail: false,
        // 当前选中的轨迹对象
        currentTrackObject: null,
        fence: {
          id: 'id',
          name: 'name'
        }
      },
      showLeftModal: true,
      treeData: [],
      // 当前选中的设备Id
      currentSelectedId: '',
      // 当前打勾的设备列表
      currentCheckedList: [],
      // 人员状态信息
      cleanerInfoArray: [],
      currentActiveTabKey: '1',
      // 当前地图坐标对应的地址
      currentMapAddress: '',
      // 当前选中的物体对象
      objectInfo: null,
      // 设备人员数据
      deviceEmployeeArray: [],
      // 地图对象
      MapObj: null,
      // 显示设置信息
      showSettingInfo: false,
      // 最新对象节点数据
      recentObjectPoints: [],
      // 当前设备Id
      currentDeviceId: 0,
      // 当前轨迹数据
      currentFenceData: []
    }
  },
  watch: {
    currentCheckedList(newVal, oldVal) {
      this.getData()
    }
  },
  created() {
    this.initCleanerTree()
  },
  methods: {
    handleTabChange(activeKey) {
      this.currentActiveTabKey = activeKey
      if (activeKey === '2') {
        if (!this.objectInfo) {
          this.$warningEx('请选择保洁人员！')
          setTimeout(() => {
            this.currentActiveTabKey = '1'
          }, 0)
          return
        }
        // 历史轨迹地图
        this.mapPageParams.mapDataSource = 'track'
        this.mapPageParams.showMapTrackDetailButton = true
        setTimeout(() => {
          this.$refs.monitorRef && this.$refs.monitorRef.mapDrawFence(this.currentFenceData, true)
        }, 100)
      } else {
        // 实时状态地图
        this.mapPageParams.mapDataSource = 'real'
        this.mapPageParams.showMapTrackDetailButton = false
        this.mapPageParams.showMapTrackDetail = false
      }

      if (activeKey === '1') {
        // 重置轨迹播放数据
        this.mapPageParams.historyTrackPointsArray = []
        this.handleMapTrackPlayStop()
        // 更新数据但不需要重新渲染
        this.getData()
      }
    },
    handleCloseLeftModal() {
      this.showLeftModal = false
    },
    initCleanerTree() {
      console.log('initCleanerTree')
      const formData = new FormData()
      formData.append('type', 1)
      treeArea(formData).then((response) => {
        this.treeData = response.data
      })
    },
    setDeviceEmployeeArray(data) {
      if (!data || data.length === 0) {
        return false
      }
      data.forEach((p) => {
        if (p && p.id.indexOf('t-1-') >= 0) {
          this.deviceEmployeeArray.push({
            deviceId: p.id.substr(4),
            name: p.label
          })
        }
        if (p.children && p.children.length > 0) {
          this.setDeviceEmployeeArray(p.children)
        }
      })
    },
    // 点击选中设备，当前选中Id，当前打勾设备列表
    clickCleanerNode(selectedId, checkedList, isSameList) {
      console.log('clickCleanerNode', selectedId)
      // 手动控制是否显示弹窗信息
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
      this.currentSelectedId = selectedId
      // 先判断打勾列表是否发生变化，如果不变，则不重新加载后台数据
      if (this.currentCheckedList.toString() === checkedList.toString()) {
        // console.log('打勾列表不变')
        this.toSelectObject()
        return
      }
      this.currentCheckedList = [...checkedList]
    },
    // 选中物体
    toSelectObject() {
      const data = this.recentObjectPoints
      if (data) {
        const obj = data.find((p) => p.deviceId === this.currentDeviceId)
        if (obj && this.objectInfo && obj.deviceId === this.objectInfo.deviceId) {
          // 如果车辆Id相同，则直接更改对象属性值
          this.objectInfo = Object.assign(this.objectInfo, obj)
        } else {
          this.objectInfo = obj
        }
        if (this.mapPageParams.mapDataSource === 'track') {
          this.mapPageParams.currentTrackObject = this.objectInfo
        }
        // 内存泄漏2：每次新对象赋值，会导致内存泄漏 2022-01-19
        // this.objectInfo = data.find((p) => p.deviceId === this.currentSelectedId)
      }
    },
    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    checkCleanerNode(checkedList, selectedId, isSameList) {
      console.log('checkCleanerNode', checkedList)
      this.currentCheckedList = checkedList
      this.currentSelectedId = selectedId
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
    },
    getData() {
      // console.log('getData')
      // 只有在实时状态页时才需要更新数据
      if (this.currentActiveTabKey !== '1') {
        const obj = this.currentFenceData.find((p) => p.id === this.currentSelectedId)
        if (obj) {
          return
        }
      }
      // 如果没有打勾的设备，则地图上不显示设备
      if (this.currentCheckedList.length === 0) {
        this.objectInfo = null
        this.mapPageParams.objectPoints = []
        this.$refs.monitorRef && this.$refs.monitorRef.mapDrawFence([])
        return
      }

      // 如果没有选中设备，则地图上不显示设备的气泡
      if (!this.currentSelectedId) {
        this.objectInfo = null
      }
      getAreaOnDutyByAreaIds(this.currentCheckedList.join(',')).then((res) => {
        if (res.data) {
          const data = res.data
          // 设备详细信息列表
          const deviceData = []
          data.forEach((p) => {
            if (p.deviceStatusList) {
              deviceData.push(...p.deviceStatusList)
            }
          })

          this.recentObjectPoints = deviceData.map((p) => {
            p.lng = p.coordinates[0]
            p.lat = p.coordinates[1]
            p.speed = Number(p.speed) / 10
            return p
          })
          // 更新围栏
          const fenceData = data.map((p) => {
            const obj = { ...p }
            obj.deviceStatusList = null
            return obj
          })
          this.currentFenceData = fenceData
          this.$refs.monitorRef && this.$refs.monitorRef.mapUpdateObjectPoints(this.recentObjectPoints)
          this.$refs.monitorRef && this.$refs.monitorRef.mapDrawFence(fenceData)

          this.toSelectObject()
        }
      })
    },
    handleMapPageClick(eventName, eventArgs) {
      if (eventName === 'showLeftModal') {
        this.showLeftModal = eventArgs
      } else if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'selectObjectInfo') {
        console.log('selectObjectInfo')
        // 地图上选中物体
        this.currentDeviceId = eventArgs.deviceId
        this.toSelectObject()
      } else if (eventName === 'selectObjectInfoFence') {
        console.log('selectObjectInfoFence')
        // 地图上选中围栏
        this.currentSelectedId = eventArgs + ''
      } else if (eventName === 'setMapObj') {
        this.MapObj = eventArgs
      }
    },
    // 地图上方工具栏点击事件
    handleMapToolClick(eventName, eventArgs) {
      // 显示地图轨迹明细
      if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'refreshData') {
        this.getData()
      }
    },
    handleHistoryTrackSearch(eventArgs) {
      console.log('handleHistoryTrackSearch', eventArgs)
      if (!this.currentDeviceId) {
        this.$warningEx('请先选中工牌')
        return
      }
      // 暂停轨迹播放
      this.handleMapTrackPlayStop()
      this.loading = true
      const queryParam = {}
      queryParam.startTime = eventArgs.startTime
      queryParam.endTime = eventArgs.endTime
      queryParam.deviceId = this.currentDeviceId
      queryHistoryCoordinates(queryParam).then((response) => {
        const data = response.data
        if (data.length === 0) {
          this.$info({
            title: '提示',
            content: '查询不到数据！'
          })
        }
        this.mapPageParams.historyTrackPointsArray = data.locations.map((p) => {
          return { ...p, speed: Number(p.speed) / 10, lat: p.coordinates[1], lng: p.coordinates[0] }
        })
        this.mapPageParams.currentTrackObject = this.objectInfo
        this.mapPageParams.trackRemark = '查询期间内总在线时长：' + data.timeSum
      })
    },
    // 轨迹播放停止
    handleMapTrackPlayStop() {
      this.$refs.monitorRef.mapTrackPlayStop()
    }
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  }
}
</script>

<style lang="less">
.cleaner-zone {
  .map-control-custom {
    position: absolute;
    top: 58px;
    left: 50%;
    width: 250px;
    transform: translateX(-50%);
    text-align: center;
    .content {
      height: 50px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      span:first-child {
        font-size: 30px;
        font-weight: 700;
        color: blue;
      }
      span:last-child {
        font-size: 22px;
      }
    }
    .label {
      height: 35px;
      line-height: 35px;
      background: #d7d7d7;
    }
  }
  .map-control-custom-right {
    position: absolute;
    top: 100px;
    right: 0;
    width: 200px;
    .tool {
      background: white;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      -webkit-user-select: none;
      user-select: none;
      i {
        padding-right: 5px;
        color: #1296db;
      }
    }
  }
}
</style>
